<template>
  <v-layout wrap>
    <v-flex wrap xs12>
      <v-toolbar>
        <v-tabs v-model="tab" background-color="primary" grow>
          <v-tab>
            <v-badge color="pink" dot>
              {{ $t('verifyusers.to_be_verified') }}
            </v-badge>
          </v-tab>

          <v-tab>
            <v-badge color="green" content="6">
              {{ $t('verifyusers.verified') }}
            </v-badge>
          </v-tab>
        </v-tabs>
      </v-toolbar>
    </v-flex>

    <v-flex v-if="tab === 0" xs12>
      <v-card v-if="is_admin" xs12>
        <v-toolbar v-if="!es_rrhh_doctorclic" card dense color="transparent">
          <span class="headline font-weight 400">{{ $t('verifyusers.verified_pendings') }}</span>
          <v-spacer />
          <v-btn color="primary" :disabled="disabledButton" style="float: right;" @click.native="save()">{{
            $t('common.save')
          }}</v-btn>
        </v-toolbar>

        <v-card
          v-for="professional in professionals"
          :id="'prof_' + professional.id"
          :key="professional.id"
          class="mb-3"
        >
          <v-card-title>
            <span>
              <b>Nombre:</b> {{ professional.name }}<br />
              <b>Apellidos:</b> {{ professional.surname }}<br />
              <b>Email:</b> {{ professional.email }}<br />
              <b>Nª colegiado:</b> {{ professional.collegiate }}<br />
              <b>DNI/NIE:</b> {{ professional.dni }}<br />
              <b>Teléfono:</b> {{ professional.phone }}<br />
              <b>Creado el </b>{{ professional.created_at }}<br />
              <v-btn depressed small color="error" @click="openDialogReject(professional.id)">{{
                $t('verifyusers.reject')
              }}</v-btn
              ><br />
              <v-checkbox
                v-model="professional.verified"
                color="primary"
                label="Selecciona y guarda para verificar"
                @change="check($event, professional.id)"
                >Verificar</v-checkbox
              ></span
            >
          </v-card-title>

          <v-layout wrap>
            <v-flex xs12 sm5 offset-sm1>
              <img class="dni-image" :src="pathDniFront + '/' + professional.id" />
            </v-flex>
            <v-flex xs12 sm5 offset-sm1>
              <img class="dni-image" :src="pathDniBack + '/' + professional.id" />
            </v-flex>
          </v-layout>
        </v-card>
      </v-card>
    </v-flex>
    <v-flex v-else-if="tab === 1" xs12>
      <v-card v-if="is_admin && !errorAlreadyVerified" xs12>
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title>
              <v-layout wrap>
                <v-flex xs1>
                  <b>ID</b>
                </v-flex>
                <v-flex xs3>
                  <b>Nombre</b>
                </v-flex>
                <v-flex xs3>
                  <b>Email</b>
                </v-flex>
                <v-flex xs3>
                  <b>Creado el</b>
                </v-flex>
              </v-layout>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile v-for="professional in verifiedProfessionals" :key="professional.id">
          <v-list-tile-content>
            <v-list-tile-title class="prof">
              <v-layout wrap>
                <v-flex xs1>
                  {{ professional.id }}
                </v-flex>
                <v-flex xs3>
                  {{ professional.name }}
                </v-flex>
                <v-flex xs3>
                  {{ professional.email }}
                </v-flex>
                <v-flex xs3>
                  {{ professional.created_at }}
                </v-flex>
              </v-layout>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-card>
      <v-card v-if="errorAlreadyVerified">
        No se pueden cargar datos
      </v-card>
    </v-flex>

    <v-dialog v-model="reject.dialog" max-width="500px">
      <v-card>
        <v-card-title v-if="reject.sending">{{ $t('verifyusers.loading') }}</v-card-title>
        <v-card-title v-else class="headline">{{ $t('recipe.reject_prof') }}</v-card-title>
        <v-card-text v-if="!reject.sending">
          <v-layout wrap>
            <v-flex v-if="reject.apply_info" xs12>
              <v-textarea
                v-model="reject.text"
                solo
                name="input-7-4"
                label="Este texto se le mandará por email al profesional"
              />
            </v-flex>
          </v-layout>
          <v-btn v-if="!reject.apply_info" color="green darken-1" text @click="showApplyInfo">
            {{ $t('recipe.reject_with_notification') }}
          </v-btn>
        </v-card-text>
        <v-card-actions v-if="!reject.sending">
          <v-spacer />
          <span v-if="reject.apply_info">
            <v-btn color="grey darken-1" text @click="reject.apply_info = false">
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn color="green darken-1" text @click="sendApplyInfo">
              {{ $t('mensajeria.enviar') }}
            </v-btn>
          </span>
          <span v-else>
            <v-btn color="grey darken-1" text @click="closeReject">
              {{ $t('common.cancel') }}
            </v-btn>

            <v-btn color="red darken-1" text @click="rejectProf">
              {{ $t('recipe.reject_without_notification') }}
            </v-btn>
          </span>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import {
  getUnverifiedProfessionals,
  verifyProfessionals,
  alreadyVerifiedProfessionals,
  rejectProfessional,
  requestMoreInformation,
  getDniFront,
  getDniBack,
  getHeader,
} from '@/config/config';

export default {
  data: () => ({
    name: 'VerifyUsers',
    professionals: [],
    verifiedProfessionals: [],
    is_admin:
      JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 1,
    ids_to_update: [],
    disabledButton: true,
    pathDniFront: getDniFront,
    pathDniBack: getDniBack,
    tab: 0,
    errorAlreadyVerified: false,
    reject: {
      dialog: false,
      id_reject: null,
      apply_info: false,
      text: '',
      sending: false,
    },
  }),

  computed: {},

  mounted() {
    if (this.is_admin) {
      this.getUnverifiedProfessionals();
      this.getAlreadyVerified();
    } else {
      this.$router.push('/dashboard');
    }
  },

  methods: {
    ...mapActions('layout', ['showAlert']),

    getUnverifiedProfessionals() {
      Vue.http.get(getUnverifiedProfessionals, { headers: getHeader() }).then(response => {
        if (response.status === 200 && response.body.status === 1 && response.data) {
          this.professionals = response.body.data;
        }
      });
    },

    check(isCheck, id) {
      if (isCheck) {
        if (!this.ids_to_update.includes(id)) {
          this.ids_to_update.push(id);
        }
      } else {
        if (this.ids_to_update.includes(id)) {
          const index = this.ids_to_update.indexOf(id);
          this.ids_to_update.splice(index, 1);
        }
      }
      this.disabledButton = this.ids_to_update.length > 0 ? false : true;
    },

    save() {
      this.disabledButton = true;
      this.$http
        .post(verifyProfessionals, { ids: this.ids_to_update }, { headers: getHeader() })
        .then(res => {
          if (res.status === 200 && res.data.status === 1) {
            this.showAlertMsg('success', '', this.$t('success.save_common'));
            this.savedOk = true;
          } else {
            this.showAlertMsg('error', '', this.$t('errors.try_again'));
          }
        })
        .catch(err => {
          const textAlert = err.data && err.data.error ? this.$t(err.data.error) : this.$t('errors.try_again');
          this.showAlertMsg('error', '', textAlert);
        })
        .finally(() => {
          this.getUnverifiedProfessionals();
          this.ids_to_update = [];
          this.loading = false;
        });
    },

    showAlertMsg(color, icon, msg) {
      this.showAlert({
        color: color,
        icon: icon,
        message: msg,
      });
    },

    getAlreadyVerified() {
      this.$http
        .post(alreadyVerifiedProfessionals, {}, { headers: getHeader() })
        .then(res => {
          if (res.status === 200 && res.body.status === 1) {
            this.verifiedProfessionals = res.body.professionals;
          } else {
            this.errorAlreadyVerified = true;
          }
        })
        .catch(() => {
          this.errorAlreadyVerified = true;
        });
    },

    openDialogReject(prof_id) {
      this.reject.dialog = true;
      this.reject.id_reject = prof_id;
    },

    rejectProf() {
      this.reject.sending = true;
      this.$http
        .post(rejectProfessional, { prof_id: this.reject.id_reject }, { headers: getHeader() })
        .then(res => {
          if (res.status === 200 && res.body.status === 1) {
            this.showAlertMsg('success', '', this.$t('success.save_common'));
            document.getElementById('prof_' + this.reject.id_reject).style.display = 'none';
          } else {
            this.showAlertMsg('error', '', this.$t('errors.try_again'));
          }
        })
        .catch(() => {
          this.showAlertMsg('error', '', this.$t('errors.try_again'));
        })
        .finally(() => {
          this.closeReject();
        });
    },

    showApplyInfo() {
      this.reject.apply_info = true;
    },

    sendApplyInfo() {
      if (this.reject.text.trim() !== '') {
        this.reject.sending = true;
        this.$http
          .post(
            requestMoreInformation,
            { prof_id: this.reject.id_reject, message: this.reject.text },
            { headers: getHeader() }
          )
          .then(res => {
            if (res.status === 200 && res.body.status === 1) {
              this.showAlertMsg('success', '', this.$t('success.save_common'));
            } else {
              this.showAlertMsg('error', '', this.$t('errors.try_again'));
            }
          })
          .catch(() => {
            this.showAlertMsg('error', '', this.$t('errors.try_again'));
          })
          .finally(() => {
            this.closeReject();
          });
      }
    },

    closeReject() {
      this.reject.dialog = false;
      this.reject.id_reject = null;
      this.reject.apply_info = false;
      this.reject.text = '';
      this.reject.sending = false;
    },
  },
};
</script>
<style>
.dni-image {
  max-width: 90%;
}

.prof {
  border-bottom: 1px solid #d3d3d3;
}
</style>
